import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SpinnerOverlayService } from '../services/spinner-overlay.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    private currentRequests: number;
    private spinnerSubscription: Subscription | undefined;

    private urlsSkipped: string[] = [
        'wiki/getInlineWiki',
        'wiki/isAvailable',
        'healthscore/scoreFactor',
        'user/details',
        'user/licenses',
        'user/specialPermissions',
        'user/adoGroups/',
        'user/mersypersonae/',
        'user/usermersygroupfunction/',
        'user/dataAnalytics',
        'user/enrichmgmtBranches',
        'user/dirIndirUserRoles',
        'user/branches',
        'tenantspecificconf/dialerproperties',
        'user/groupfilterlist',
        'marketquery/market-sheet',
        'user/filter',
        'user/allrelatedroles',
        'virtualprovisioning/unittodevicemapping',
        'virtualprovisioning/unprovisionedassetbyunitid'
    ]
    constructor(private readonly spinnerOverlayService: SpinnerOverlayService) {
        this.currentRequests = 0;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.skipUrls(request.url!) === false)
            this.incrementRequestCount();

        return next.handle(request).pipe(
            // finalize will be called when the source terminates on complete or error.The specified function will also be called when the subscriber explicitly unsubscribes
            finalize(() => {
                if (this.skipUrls(request.url!) === false)
                    this.decrementRequestCount(next, request);
                if (this.currentRequests < 0)
                    this.currentRequests = 0;
            })
        );
    }

    private decrementRequestCount(next: HttpHandler, request: HttpRequest<any>) {
        if (--this.currentRequests <= 0 && this.spinnerSubscription) {
            this.spinnerSubscription.unsubscribe();
            this.spinnerSubscription = undefined;
        }
    }

    private incrementRequestCount() {
        if (this.currentRequests++ === 0) {
            this.spinnerSubscription = this.spinnerOverlayService.spinner$.subscribe();
        }
    }
    private skipUrls(url: string) {
        return this.urlsSkipped.some(x => url.toLowerCase().includes(x.toLowerCase()))
    }
}
