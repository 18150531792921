<div class="edituser edituser_tree">
    <div class="UserEdit">

        <!--Search AddOn-->
        <aside _ngcontent-lbe-c19="" class="ng-tns-c19-13 stickySearchIcon">
            <!---->
            <button _ngcontent-lbe-c19="" class="button-color-fix_purple" mat-mini-fab="" (click)="showSearchAddonMethod()">
                <span class="mat-button-wrapper" style="padding:0px;" *ngIf="showSearchAddon === false" title="Search users">
                    <mat-icon role="img" style="font-size:20px !important;height:20px !important; width:20px !important"
                              aria-hidden="true" data-mat-icon-type="font">search</mat-icon>
                </span>
                <span class="mat-button-wrapper" style="padding:0px;" *ngIf="showSearchAddon === true">
                    <mat-icon role="img" style="font-size: 20px !important; height: 20px !important; width: 20px !important"
                              aria-hidden="true" data-mat-icon-type="font">close</mat-icon>
                </span>
                <span class="mat-button-ripple mat-ripple mat-button-ripple-round"
                      matripple="">
                </span>
                <span class="mat-button-focus-overlay"></span>
            </button>
        </aside>
        <div class="stickyTopSrch">
            <div class="panel-body darkBackground_addon" [hidden]="showSearchAddon === false">
                <searchaddon></searchaddon>
            </div>
        </div>

        <!--Not accepted invitation User Warning-->
        <div class="panel-body" *ngIf="userDetails.isPendingAcceptance === null ? false : userDetails.isPendingAcceptance">

            <div class="row heading-background-yellow mt-5">

                <div class="col-lg-12">
                    <h4 style="color:black">
                        WARNING!
                    </h4>
                </div>

            </div>

            <div class="row custom-warn-body">

                <div class="row ">

                    <div class="col-lg-11">
                        <div class="row ">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <svg xmlns="http://www.w3.org/2000/svg" class="pull-right icon icon-tabler icon-tabler-mail-exclamation bounce" width="75" height="75" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff9300" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5.5" />
                                    <path d="M3 7l9 6l9 -6" />
                                    <path d="M19 16v3" />
                                    <path d="M19 22v.01" />
                                </svg>
                            </div>

                            <div class="col-lg-7" style="margin-block: 1.5%;">
                                <h5 style="color:black">User has not accepted the invitation. Click the button to resend the invitation.</h5>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1" style="margin-top: 2.0%;">
                        <button mat-raised-button (click)="handleResendIvite()" type="submit" class="pull-right btn button-color-fix_red ">
                            Resend Invitation
                            <mat-icon class=" icon-color-fix">forward_to_inbox</mat-icon>
                        </button>
                    </div>
                </div>



            </div>
        </div>

        <!--User Details-->
        <div class="panel-body row">
            <h4>Update User Details</h4>
        </div>

        <div *ngIf="userDetails.userPrincipalName==='' && (defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage() )" class="row zoomIn panel-body">
            <div class="form-group col-lg-6 ">
                <div class="blackbg card__desc">
                    <div *ngFor="let _ of [].constructor(3)" class="card__desc-text-form"></div>
                    <div class="card__desc-text"></div>
                </div>
            </div>
            <div class="form-group col-lg-6 ">
                <div class="blackbg card__desc">
                    <div *ngFor="let _ of [].constructor(3)" class="card__desc-text-form"></div>
                    <div class="card__desc-text"></div>
                </div>
            </div>
        </div>
        <div *ngIf="userDetails.userPrincipalName==='' && (defaults.componentTool === componentToolEnum.Mersy || defaults.isDataAnalytStage()) " class="row zoomIn panel-body">
            <div class="form-group col-lg-6 ">
                <div class="blackbg card__desc">
                    <div *ngFor="let _ of [].constructor(2)" class="card__desc-text-form"></div>
                </div>
            </div>
            <div class="form-group col-lg-6 ">
                <div class="blackbg card__desc">
                    <div *ngFor="let _ of [].constructor(2)" class="card__desc-text-form"></div>
                </div>
            </div>
        </div>
        <form *ngIf="userDetails.userPrincipalName != ''" [formGroup]="editUserForm" class=" panel-body internalMax zoomIn" autocomplete="off" style="display:contents">
            <div class="stickyTopDetls">
                <div class="form-group col-lg-6 zoomIn">
                    <label class="col-lg-4 control-label ">Username</label>
                    <div class="col-lg-8 ">
                        <p>{{userDetails.userEmail}}</p>
                    </div>
                </div>


                <div class="form-group col-lg-6 zoomIn">
                    <label class="col-lg-4 control-label">Display Name<span class="orange-asterisk"> *</span></label>
                    <div class="col-lg-8">
                        <input [readonly]="defaults.isDataAnalytStage()" *ngIf="!stickyPinned" class="form-control" formControlName="displayName" required>
                        <p *ngIf="stickyPinned">{{userDetails.displayName}}</p>
                        <span class="field-validation-valid text-danger" data-valmsg-for="DisplayName" data-valmsg-replace="true"></span>
                        <p *ngIf="
                                              editUserForm.get('displayName')?.invalid &&
                                                (editUserForm.get('displayName')?.dirty ||
                                                  editUserForm.get('displayName')?.touched)">
                            <mat-error *ngIf="editUserForm.get('displayName')?.hasError('required')">Display Name is required</mat-error>
                        </p>
                        <p *ngIf="!(
                                              editUserForm.get('displayName')?.invalid &&
                                                (editUserForm.get('displayName')?.dirty ||
                                                  editUserForm.get('displayName')?.touched))"><br> </p>
                    </div>

                </div>

            </div>

            <div class="row">
                <div class="form-group col-lg-6 zoomIn">
                    <label class="col-lg-4 control-label">First Name<span class="orange-asterisk"> *</span></label>
                    <div class="col-lg-8">
                        <input [readonly]="defaults.isDataAnalytStage()" class="form-control" formControlName="firstName" required>
                        <span class="field-validation-valid text-danger" data-valmsg-for="firstName" data-valmsg-replace="true"></span>
                        <p *ngIf="
                                              editUserForm.get('firstName')?.invalid &&
                                                (editUserForm.get('firstName')?.dirty ||
                                                  editUserForm.get('firstName')?.touched)">
                            <mat-error *ngIf="editUserForm.get('firstName')?.hasError('required')">First Name is required</mat-error>
                        </p>
                        <p *ngIf="!(
                                              editUserForm.get('firstName')?.invalid &&
                                                (editUserForm.get('firstName')?.dirty ||
                                                  editUserForm.get('firstName')?.touched))"><br> </p>
                    </div>
                </div>
                <div class="form-group col-lg-6 zoomIn">
                    <label class="col-lg-4 control-label">Last Name<span class="orange-asterisk"> *</span></label>
                    <div class="col-lg-8">
                        <input [readonly]="defaults.isDataAnalytStage()" class="form-control" formControlName="lastName" required>
                        <span class="field-validation-valid text-danger" data-valmsg-for="lastName" data-valmsg-replace="true"></span>
                        <p *ngIf="
                                              editUserForm.get('lastName')?.invalid &&
                                                (editUserForm.get('lastName')?.dirty ||
                                                  editUserForm.get('lastName')?.touched)">
                            <mat-error *ngIf="editUserForm.get('lastName')?.hasError('required')">Last Name is required</mat-error>
                        </p>
                        <p *ngIf="!(
                                              editUserForm.get('lastName')?.invalid &&
                                                (editUserForm.get('lastName')?.dirty ||
                                                  editUserForm.get('lastName')?.touched))"><br> </p>
                    </div>
                </div>
            </div>

            <div *ngIf="!defaults.isDataAnalytStage()" class="row">
                <div class="form-group col-lg-6 zoomIn" *ngIf="defaults.componentTool === componentToolEnum.Rulebook">
                    <!--<div class="form-group col-lg-6 " *ngIf="false">-->
                    <label class="col-lg-4 control-label">
                        Primary Tenant<span class="orange-asterisk"> *  </span>
                        <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="Country Code (following two characters ISO convention). This filter specifies user's primary or secondary tenant." aria-hidden="true"></span>
                    </label>
                    <div class="col-lg-8">
                        <ng-select (change)="onPrimTenChange($event)"
                                   [items]="userDetails.tenantsSelectList"
                                   [searchable]="true"
                                   [clearable]="false"
                                   class="tenantStyling required"
                                   bindLabel="text"
                                   bindValue="value"
                                   formControlName="tenant"
                                   [dropdownPosition]="'bottom'"
                                   required>
                        </ng-select>
                    </div>
                </div>

                <div class="form-group col-lg-6 zoomIn">
                    <label class="col-lg-4 control-label">Company Name</label>
                    <div class="col-lg-8">
                        <input class="form-control" formControlName="companyName">
                        <span class="field-validation-valid text-danger" data-valmsg-for="lastName" data-valmsg-replace="true"></span>
                        <!--<p *ngIf="
                              editUserForm.get('companyName')?.invalid &&
                                (editUserForm.get('companyName')?.dirty ||
                                  editUserForm.get('companyName')?.touched)">
                        <mat-error *ngIf="editUserForm.get('companyName')?.hasError('required')">Sponsor is required</mat-error>
                    </p>
                    <p *ngIf="!(
                              editUserForm.get('sponsor')?.invalid &&
                                (editUserForm.get('sponsor')?.dirty ||
                                  editUserForm.get('sponsor')?.touched))"><br> </p>-->
                    </div>
                </div>
                <!--<div class="form-group col-lg-6 " *ngIf="defaults.componentTool === componentToolEnum.Rulebook">-->
                <div class="form-group col-lg-6  zoomIn" *ngIf="false">
                    <label class="col-lg-4 control-label">
                        Secondary Tenant
                        <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="Other tenants for which the user is part of." aria-hidden="true"></span>
                    </label>
                    <div class="col-lg-8">
                        <ng-select (change)="onSecTenChange()"
                                   [matTooltip]="selectedSecTenString"
                                   [matTooltipPosition]="'above'"
                                   [items]="userDetails.secondaryTenantsSelectList"
                                   groupBy="selectedAllGroup"
                                   [selectableGroup]="true"
                                   [selectableGroupAsModel]="false"
                                   [searchable]="true"
                                   [clearable]="true"
                                   [closeOnSelect]="false"
                                   [multiple]="true"
                                   bindLabel="text"
                                   bindValue="value"
                                   formControlName="secondaryTenants"
                                   (open)="onOpenSecondaryTenants()"
                                   [dropdownPosition]="'bottom'"
                                   (add)="updateTenSet( { selTen:[$event.value], checked: true })"
                                   (remove)="updateTenSet( { selTen:[$event.value], checked: false })">

                            <ng-template ng-optgroup-tmp>
                                Select All
                            </ng-template>

                            <ng-template ng-option-tmp
                                         let-item="item">
                                {{ item.text }}
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="items-selected" *ngIf="this.editUserForm.controls['secondaryTenants'].value.length <= 2">
                                    <div class="ng-value" *ngFor="let item of this.editUserForm.controls['secondaryTenants'].value">
                                        <span class="ng-value-label">{{item}}</span>

                                    </div>
                                </div>

                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label">{{items.length}} Selected...</span>
                                </div>

                            </ng-template>

                        </ng-select>
                    </div>
                </div>


            </div>

            <br>
            <div class="row" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()">
                <div class="form-group col-lg-6 zoomIn">
                    <p class="darkBackground">
                        <mat-checkbox formControlName="active" class="pl-2">
                            Active
                            <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="This flag will enable/disable the user in the AAD, so the user is not removed but it will not be able to login in any of the platforms." aria-hidden="true"></span>
                        </mat-checkbox>
                    </p>
                </div>
                <div class="form-group col-lg-6 zoomIn">
                    <p class="darkBackground">
                        <mat-checkbox [disabled]="true" formControlName="inviteUser" class="pl-2">
                            Azure Analysis Service Access
                            <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="Mark this flag if the user needs to gain access to the reporting section from the MAX analytic project. Internal reporting." aria-hidden="true"></span>
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="row" *ngIf="isProductionEnvironment === true && (
                        (defaults.componentTool === componentToolEnum.Mersy && !defaults.isMersyAdminMarketQueryUser) || defaults.isadminuser === true)">
                <div class="form-group col-lg-6 zoomIn">
                    <p class="darkBackground">
                        <mat-checkbox formControlName="mersyAccessReport" class="pl-2" (change)="onMersyDirectClick($event)">
                            Mersy Direct-Report - Power BI
                            <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="Mark this flag if the user needs to gain access in CDP Mersy Direct-Report - Power BI." aria-hidden="true"></span>
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="panel-body row button-container stickyTopBtns zoomIn">
                <div class="left-buttons">

                    <button *ngIf="!defaults.isDataAnalytStage()" mat-raised-button (click)="handledelbutton($event)" class="btn button-color-fix_red ">
                        Delete
                        <mat-icon class=" icon-color-fix">delete</mat-icon>
                    </button>
                    <button mat-raised-button *ngIf="!defaults.isDataAnalytStage() && (editUserForm.get('isexternaluser')?.value === null ? false : !editUserForm.get('isexternaluser')?.value)" (click)="handleresetbutton($event)" class="btn button-color-fix ">
                        Password
                        <mat-icon class=" icon-color-fix">lock</mat-icon>
                    </button>
                    <button mat-raised-button *ngIf="!defaults.isDataAnalytStage() || defaults.isadminuser " (click)="clearCacheUser($event)" class="btn button-color-fix ">
                        Clear Cache
                        <mat-icon class=" icon-color-fix">delete_sweep</mat-icon>
                    </button>
                    <button mat-raised-button *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()" (click)="getUserCache($event)" class="btn button-color-fix ">
                        Check Cache
                        <mat-icon class=" icon-color-fix">open_in_new</mat-icon>
                    </button>
                    <button mat-raised-button *ngIf="defaults.isadminuser" (click)="checkUserHistory($event)" class="btn button-color-fix btn_grey">
                        Check User History
                        <mat-icon class=" icon-color-fix">open_in_new</mat-icon>
                    </button>
                </div>
                <!--<button mat-raised-button type="submit" class="btn right-buttons button-color-fix pull-right " [disabled]="(!editUserForm.valid || disableSaveButton)">
                Save
                <mat-icon class="icon-color-fix">save</mat-icon>
            </button>-->



            </div>
        </form>


        <br />


        <!--User Special Permisssions Panel-->
        <div class="panel-body zoomIn" *ngIf="defaults.isadminuser === true && defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()">
            <specialpermissions></specialpermissions>
        </div>
        <!--User License Mgmt Panel-->
        <div class="panel-body zoomIn" *ngIf="defaults.stage === 1 && defaults.IsLicenseManagerUser === true && defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()">
            <licensemgmt></licensemgmt>
        </div>
        <!--User License Panel-->
        <div class="panel-body zoomIn" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage() &&
                                                ((defaults.isProdUserManagementPortalUser === true && defaults.stage === 1)
                                                || (defaults.isDevUserManagementPortalUser === true && defaults.stage === 0)
                                                 || defaults.isadminuser)">
            <div class="row heading-background mt-5">

                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <h4>Assigned Licenses</h4>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 search-container">

                    <div (change)="updateLicenseResults()" (keyup)="updateLicenseResults()">
                        <input class="form-control" type="text" placeholder="Search" [(ngModel)]="licenseSearchValue">
                    </div>
                    <button mat-raised-button (click)="funcGetUserLicenses()" aria-label="Reset" class="btn btn-sm button-color-fix ">
                        <mat-icon>cached</mat-icon>
                    </button>
                </div>

            </div>

            <div class="custom_small_panelbody_fix">
                <div class="row custom_panelbody_fix custom_panelbody_fix">

                    <div *ngIf='License_Response_State=== 1' class=" pl-1">

                        <ul>
                            <li *ngFor="let item of filteredLicenseItems; let even = even"
                                [ngClass]="{'even': even, 'odd': !even}">
                                <mat-checkbox type="checkbox" [checked]="item.isAssigned" (click)="$event.preventDefault()" style="cursor: unset" disabled>
                                    {{ item.skuName }}
                                </mat-checkbox>

                            </li>
                        </ul>
                    </div>

                    <div *ngIf='License_Response_State===-2' style=" padding: 20px !important;">
                        <div class="blackbg card__desc">
                            <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!--Azure DevOps Project Areas-->
        <div class="panel-body zoomIn" *ngIf="(defaults.stage === 1 && defaults.isProdADOGroupUMPUser === true )
                                            || (defaults.stage === 0 && defaults.isDevADOGroupUMPUser === true ) && defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()">
            <azdevproj [groupmgmtcallback]="groupMgmtCallback"></azdevproj>
        </div>

        <!--Max Roles Panel-->
        <directindirectgeneric class="zoomIn" [componentName]="'Max Role'" [umpCategoryName]="'MaxRoles'" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage() &&
                                                                        (   (defaults.isProdUserManagementPortalUser === true && defaults.stage === 1)
                                                                         || (defaults.isDevUserManagementPortalUser === true && defaults.stage === 0)
                                                                         || defaults.isadminuser ) "></directindirectgeneric>

        <directindirectgeneric class="zoomIn" [componentName]="'Emt Role'" [umpCategoryName]="'EmtRoles'" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage() &&
                                                                        (defaults.isadminuser || defaults.isEMTManagerUser)"></directindirectgeneric>

        <directindirectgeneric class="zoomIn" [componentName]="'Home Role'" [umpCategoryName]="'HomeRoles'" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage() &&
                                                                        ((defaults.isProdUserManagementPortalUser === true && defaults.stage === 1)
                                                                         || (defaults.isDevUserManagementPortalUser === true && defaults.stage === 0))
                                                                    && (defaults.isadminuser || defaults.isHomManagerUser)"></directindirectgeneric>
        <directindirectgeneric class="zoomIn" [componentName]="'MFG Role'" [umpCategoryName]="'MfgRoles'" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage() &&
                                                                        defaults.isMfgManagerUser"></directindirectgeneric>  <!--defaults.isMfgManagerUser-->

        <directindirectgeneric class="zoomIn" [componentName]="'Market Line Of Busines'" [umpCategoryName]="'MarketLineOfBusiness'" *ngIf="(defaults.componentTool === componentToolEnum.Mersy || defaults.isadminuser) &&
                                                                defaults.isMersyAdminMarketQueryUser"></directindirectgeneric>

        <directindirectgeneric class="zoomIn" [componentName]="'Market Product Type'" [umpCategoryName]="'MarketProductType'" *ngIf="(defaults.componentTool === componentToolEnum.Mersy || defaults.isadminuser) &&
                                                        defaults.isMersyAdminMarketQueryUser"></directindirectgeneric>

        <!--Market Query Roles-->

        <div class="panel-body zoomIn" *ngIf="(defaults.componentTool === componentToolEnum.Mersy || defaults.isadminuser) && defaults.isMersyAdminMarketQueryUser">
            <mersyroles [groupmgmtcallback]="groupMgmtCallback"
                        [isMQRequest]="true"> </mersyroles>
        </div>

        <!--Mersy Roles-->

        <div class="panel-body zoomIn" *ngIf="!defaults.isDataAnalytStage() && ((defaults.stage === 1 && defaults.isProdMersyUMPUser === true )
                                            || (defaults.stage === 0 && defaults.isDevMersyUMPUser === true )
                                            || defaults.isMersyAdminUser )">
            <mersyroles [groupmgmtcallback]="groupMgmtCallback"
                        [isMQRequest]="false"> </mersyroles>
        </div>

        <!--Mercy Groups-->
        <div class="panel-body zoomIn" *ngIf="!defaults.isDataAnalytStage() && ((defaults.stage === 1 && defaults.isProdMersyUMPUser === true )
                                            || (defaults.stage === 0 && defaults.isDevMersyUMPUser === true )
                                            || defaults.isMersyAdminUser )">
            <mersygroups [groupmgmtcallback]="groupMgmtCallback"> </mersygroups>
        </div>

        <!--Data Analytics and PowerBI Workspaces Panel-->
        <div class="panel-body zoomIn" *ngIf="defaults.componentTool === componentToolEnum.Rulebook  &&
                                                                        ((defaults.isProdUserManagementPortalUser === true && defaults.stage === 1)
                                                                         || (defaults.isDevUserManagementPortalUser === true && defaults.stage === 0)
                                                                         || ( defaults.stage === 4))">
            <daworkspace [groupmgmtcallback]="groupMgmtCallback"> </daworkspace>
        </div>


        <!--Branch Hierarchy-->
        <div class="panel-body zoomIn" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage() &&
                                                                ((defaults.isProdUserManagementPortalUser === true && defaults.stage === 1)
                                                                 || (defaults.isDevUserManagementPortalUser === true && defaults.stage === 0)
                                                                    || defaults.isEMTManagerUser === true
                                                                    || defaults.isMfgManagerUser ===true )">
            <umpbranchhierarchy (secondaryTenUpdate)="updateTenSet($event)" [reload]="reload" [groupmgmtcallback]="groupMgmtCallback"> </umpbranchhierarchy>
        </div>

        <!--Management Branch Heirarchy-->
        <div class="panel-body zoomIn" *ngIf="!defaults.isDataAnalytStage() && ((defaults.stage === 1 && defaults.isProdMersyUMPUser === true )
                                            || (defaults.stage === 0 && defaults.isDevMersyUMPUser === true )
                                            || defaults.isMersyAdminUser
                                            || defaults.isMersyAdminMarketQueryUser)">
            <usermgmtbranch [groupmgmtcallback]="groupMgmtCallback"> </usermgmtbranch>
        </div>



    </div>

</div>


<div class="stickypanel">

    <button mat-raised-button routerLink="/UmpHome" type="submit" class="btn  button-color-fix_red ">
        <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
        Back
    </button>
    <button mat-raised-button (click)="saveService.fireSave()" type="submit" class="btn button-color-fix" [disabled]="(!editUserForm.valid || disableSaveButton) && saveService.listeners.length === 0">
        <mat-icon class="icon-color-fix">save</mat-icon>
        Save
    </button>
</div>

