import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HistoryLeverDialog } from '../history-lever-dialog/history-lever-dialog.component';

@Component({
    selector: 'assessment-list',
    templateUrl: './assessment-list.component.html',
    styleUrls: ['./assessment-list.component.less',
        '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})
export class AssessmentListComponent implements OnInit {

    @Input('assessmentInfo') assessmentInfo: Assessment | undefined;
    @Input('stages') stages: ExcellenceStage[] = [];
    @Input('contacts') contacts: BranchContact[] = [];
    @Input('isEditModeEnabled') isEditModeEnabled: boolean = false;

    updatedItems: number[] = []

    columnsToDisplay = ['Factors', 'KPIs', 'Function', 'LineOfBusiness', 'ExcellenceLever', 'Stage', 'Contact', 'Action', 'Notes', 'BestPractices', 'actions'];
    subcolumnsToDisplay = ['Factor', 'Description'];
    subcolumnsKpisToDisplay = ['AccountId', 'GF', 'Description'];

    public dataSource = new MatTableDataSource<any>();

    expandedElements = new Set();
    expandedKpis = new Set();
    isOpenTextAreaOverlay = false;

    dialogRef: any;
    constructor(
        private dialogForm: MatDialog
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: any) {
        if (changes.assessmentInfo) {
            const data: any[] = [];
            let indexCounter = 0;
            this.assessmentInfo?.Functions.forEach((za: AssessmentFunction) => {
                za.LineOfBusinesses.forEach((y: AssessmentLineOfBusiness) => {
                    y.ExcellenceLevers.forEach((z: AssessmentExcellenceLever) => {
                        data.push({
                            ...z,
                            Function: za.Function,
                            LineOfBusiness: y.LineOfBusiness,
                            index: indexCounter++
                        })
                    })
                })
            })
            this.dataSource.data = data
        }
    }

    pushPopElement(element: any, expandKpis: boolean) {
        if (!expandKpis) {
            if (this.expandedElements.has(element)) {
                this.expandedElements.delete(element);
            }
            else {
                this.expandedElements.add(element);
            }
        }
        else {
            if (this.expandedKpis.has(element)) {
                this.expandedKpis.delete(element);
            }
            else {
                this.expandedKpis.add(element);
            }
        }
    }
    checkExpanded(element: any, expandKpis: boolean) {
        if (!expandKpis)
            return this.expandedElements.has(element);
        else
            return this.expandedKpis.has(element);
    }
    onDetail($event: any, lever: any) {
        $event.preventDefault();
        this.dialogRef = this.dialogForm.open(HistoryLeverDialog, {
            height: '525px',
            width: '1000px',
            data: { lever: lever }
        });
        this.dialogRef.afterClosed().subscribe(() => {
        });
    }

    onChange(waveExcellenceLeverConfigId: number) {
        if (!this.updatedItems.includes(waveExcellenceLeverConfigId))
            this.updatedItems.push(waveExcellenceLeverConfigId)
    }

    onTextFieldChange(comment: string, textAreaId: string, lever: any) {
        if (lever) {
            let type = textAreaId.split('_')[0];
            switch (type) {
                case 'Action':
                    lever.Action = comment;
                    break;
                case 'Notes':
                    lever.Notes = comment;
                    break;
                case 'BestPractices':
                    lever.BestPractices = comment;
                    break;
            }
            this.onChange(lever.WaveExcellenceLeverConfigId)
        }
    }
}
