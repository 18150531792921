import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ComponentTool } from '../interfaces/componenttool';
import { DefaultValuesService } from './defaultvalues.service';


@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public defaults: DefaultValuesService) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        while (!this.defaults.loadInitialData) {
            console.log("Waiting for Initialize UI...")
            await new Promise(r => setTimeout(r, 2000));
        }
        let activate = this.defaults.isadminuser;
        switch (route.data['role']) {
            case 'msuser': activate = (activate || this.defaults.isExternalUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'countrybackenduser': activate = (activate || this.defaults.isCountryBackendStableUser || this.defaults.isCountryBackendProdUser || this.defaults.isHomeUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'domainexpertuser': activate = (activate || this.defaults.isDomainExpertUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'homeuser': activate = (activate || this.defaults.isHomeUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'it_domainexpert_user': activate = (activate || this.defaults.isCountryBackendProdUser || this.defaults.isCountryBackendStableUser || this.defaults.isDomainExpertUser || this.defaults.isExternalUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'umpuser': activate = (activate || ((this.defaults.isDevUserManagementPortalUser || this.defaults.isProdUserManagementPortalUser || this.defaults.isEMTManagerUser) && this.defaults.componentTool === ComponentTool.Rulebook)
                || ((this.defaults.isMersyAdminUser || this.defaults.isMersyAdminMarketQueryUser) && this.defaults.componentTool === ComponentTool.Mersy)); break;
            case 'branchexcellence': activate = activate
                || (this.defaults.isMersyAdminBranchExcellenceUser && this.defaults.componentTool === ComponentTool.Mersy); break;
            case 'mersyuser': activate = (activate || this.defaults.IsMersyUser === true) && this.defaults.componentTool === ComponentTool.Mersy; break;
            case 'globalsystemadmin': activate = (activate || this.defaults.isGlobalSystemAdmin) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'usermgmtfldbranchesuser': activate = (activate || this.defaults.isUserMgmtFldBranchesUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'mfgadmin': activate = (activate || this.defaults.isMFGAdminUser || this.defaults.isMFGEquipmentManagerUser || this.defaults.isMFGAdminPDCSUser
                || this.defaults.isMFGAdminCenterUser || this.defaults.isMFGContractEngineerUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'equipmentmfg': activate = (activate || this.defaults.isMFGEquipmentManagerUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'controllersoftwareuser': activate = (activate || this.defaults.isMFGAdminPDCSUser || this.defaults.isMFGAdminCenterUser) && this.defaults.componentTool === ComponentTool.Rulebook; break;
            case 'marketquery': activate = (activate || this.defaults.isMersyAdminMarketQueryUser || this.defaults.isMersyOwnerMarketQueryUser || this.defaults.isMersyReporterMarketQueryUser
                || this.defaults.isMersyRevokerMarketQueryUser || this.defaults.isMersyDelegateMarketQueryUser || this.defaults.isMersyViewerMarketQueryUser) && this.defaults.componentTool === ComponentTool.Mersy; break;
        }
        if (activate === false) {
            window.location.href = "/"; // navigate to home page.
        }
        return true;
    }
}