import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
    selector: 'textareaoverlay',
    templateUrl: './textareaoverlay.component.html',
    styleUrls: ['./textareaoverlay.component.less']
})
export class TextAreaOverlayComponent implements OnInit {
    @Input() inputControls?: TextAreaOverlayInput;
    @Output() commentChange = new EventEmitter<{ comment: string, textAreaId: string }>();
    @Input() customSpanClass?: { [key: string]: boolean };
    constructor(private renderer: Renderer2) { }

    ngOnInit(): void {

    }

    get comment(): string {
        return this.inputControls?.comment ?? '';
    }

    set comment(value: string) {
        if (this.inputControls) {
            this.updateInputControls({ comment: value });
        }
    }

    toggleOvl() {
        if (this.inputControls) {
            this.updateInputControls({ isOpenOverlay: !this.inputControls.isOpenOverlay });
        }
    }

    fitTextAreaToContent() {
        setTimeout(() => {
            const textAreaId = this.inputControls?.textAreaId ?? '';
            let element = document.getElementById(textAreaId);
            if (element) {
                element.style.height = "";
                element.style.height = element.scrollHeight + 3 + "px";
                this.renderer.selectRootElement(element).focus();
                if (!this.inputControls?.isEditable)
                    element.setAttribute("readonly", "");
                else
                    element.removeAttribute("readonly");
            }
        }, 25);
    }

    getCommentText() {

        if (this.inputControls?.isOpenOverlay) {
            if (this.inputControls?.isEditable)
                return "Editing...";
            else
                return "Reading...";
        }
        else {
            const comment = this.inputControls?.comment ?? '';
            if (comment === '' && this.inputControls?.isEditable)
                return "Add a comment";
            else {
                return comment.length > 25 ? `${comment.substring(0, 25)}...` : comment;
            }
        }
    }

    getMaxLengthTooltipText() {
        return `Max ${this.inputControls?.maxCommentLength} characters`
    }

    private updateInputControls(updatedProperties: Partial<TextAreaOverlayInput>) {
        if (this.inputControls) {
            this.inputControls = { ...this.inputControls, ...updatedProperties };
        }
    }

    onCommentChange() {
        this.commentChange.emit({ comment: this.inputControls?.comment ?? '', textAreaId: this.inputControls?.textAreaId ?? '' });
    }

    backdropActions() {
        this.toggleOvl();
        if (this.inputControls?.isEditable)
            this.onCommentChange();
    }
}
