import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APICallStatus, Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { FilterPopupComponent } from "../../shared-components/filterpopup/filterpopup.component";
import { Router } from "@angular/router";
import { DialogService } from "../../../../services/dialog.service";
import { CustomDialogService } from "../../../../services/customdialog.service";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { ToastrService } from "ngx-toastr";
import { SegmentSheetListComponent, SearchItem } from "./segment-sheet-list.component";

@Component({
    selector: "segment-sheet",
    templateUrl: './segment-sheet.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less', '../../../../style/new-generic-styles.component.less']
})

export class SegmentSheetComponent implements OnInit, OnDestroy {


    @ViewChild(SegmentSheetListComponent) listComponent: SegmentSheetListComponent = {} as SegmentSheetListComponent;
    isReleaseActive: boolean = false;
    releaseButtonText: string = 'Initiate Release';

    isRevokeActive: boolean = false;
    revokeButtonText: string = 'Initiate Revoke';

    isEditModeEnabled = false;
    isShowAllEnabled = true;
    isExcelModeEnabled = false;

    subscriptionManagementId: any;
    subscriptionFiscalYear: any;
    subscriptions: any[] = [];

    list: KpiManagement[] = [];

    // Filter section
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    filter: string = ''
    selectorFilter = '0';
    selectorKpiUnitFilter = '0';

    inclusiveMode = false;
    isAllLoaded = false;
    waitHeader = `   -  **Please, wait until all breakdowns are properly loaded**`;
    columnsToDisplay2 = ['ItemNo', 'COL1', 'COL2', 'COL3', 'COL4', 'TKEFY', 'MarketFY', 'ShareFY', 'YoYFY', 'TKEFY1', 'MarketFY1', 'ShareFY1', 'YoYFY1', 'TKEFY2', 'MarketFY2', 'ShareFY2', 'YoYFY2', 'TKEFY3', 'MarketFY3', 'ShareFY3', 'YoYFY3'];

    showReleaseLabel = false;

    title: string | undefined;
    tableResponseState: APICallStatus = APICallStatus.NotRequested;

    constructor(
        public defaults: DefaultValuesService
        , private http: HttpClient
        , private dialogForm: MatDialog
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private router: Router
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService

    ) {
        this.title = `Segment Sheet (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear})`;

    }


    ngOnInit() {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => { this.loadInformation() });
        this.subscriptionFiscalYear = this.defaults.fiscalYearMessageChanges$.subscribe(() => { this.loadInformation() });
        if (this.defaults.managementids.length !== 0) {
            this.loadInformation();
        }
    }

    ngOnDestroy() {
        this.subscriptionManagementId.unsubscribe();
        this.subscriptionFiscalYear.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    //////////////////
    //OnClick Methods

    onClickSave() {

        var wasAnyUpdate = this.listComponent.updatedItems.length > 0

        this.listComponent.updatedItems = this.listComponent.updatedItems.filter(x => x.KpiValue !== 'NaN')

        if (this.listComponent.updatedItems.length > 0) {

            Utils.httpUpdateMarketQueryInformation(
                this.http
                , this.baseUrl
                , 'SEGMENT'
                , this.listComponent.updatedItems
                , this
                , function (tthis: SegmentSheetComponent) {
                    tthis.toastrService.success('Segment sheet values were updated successfully!');
                    tthis.isEditModeEnabled = false;
                    tthis.loadInformation()
                }
                , function (tthis: SegmentSheetComponent, error: any) {
                    tthis.dialog.showErrorDialog(error);
                }
            )
        }
        else {
            this.isEditModeEnabled = false;
            if (wasAnyUpdate)
                this.loadInformation()
        }
    }

    onClickEdit() {
        if (this.isEditModeEnabled === true) {
            this.isEditModeEnabled = false
            if (this.listComponent.updatedItems.length > 0) {
                this.loadInformation()
            }
        }
        else {
            this.isEditModeEnabled = true
        }
    }

    onClickShowAllButton() {
        if (this.isShowAllEnabled === false) {
            this.isShowAllEnabled = true
        }
        else {
            this.isShowAllEnabled = false
        }
        this.updatefilter()
    }

    onClickCancelButton() {
        this.isEditModeEnabled = false;
    }

    onClickReleaseMode() {
        var selectedRows = this.listComponent?.getSelectionList();
        if (!selectedRows)
            selectedRows = []

        this.customDialog.openConfirm({
            title: "Release values",
            message: `Do you want to release all populated values selected? Please note that you cannot edit any values after the release!. `,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpReleaseMarketQueryInfo(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , false
                    , this
                    , function (tthis: SegmentSheetComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Segment sheet values were released successfully!');
                        tthis.toggleRelease();
                    }
                );
            }
        })
    }
    onClickRevoke() {
        var selectedRows = this.listComponent?.getSelectionList();
        if (!selectedRows)
            selectedRows = []
        this.customDialog.openConfirm({
            title: "Revoke values",
            message: `Do you want to revoke all populated values for selected period?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpRevokeMarketQueryInfo(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , false
                    , this
                    , function (tthis: SegmentSheetComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Segment sheet values were revoked successfully!');
                        tthis.toggleRevoke();
                    }
                );
            }
        })
    }

    // Open filter Popup
    openFilterPopup() {
        const dialog = this.dialogForm.open(FilterPopupComponent, {
            panelClass: ['custom-mat-dialog', 'popup-dialog'],
            disableClose: true,
            data: { "selectorFilter": this.selectorFilter, "inclusiveMode": this.inclusiveMode }
        })
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.selectorFilter = result.selectorFilter;
                this.inclusiveMode = result.inclusiveMode;
                this.updatefilter()
            }
        })
    }


    onSelectorChange($event: string) {
        this.selectorFilter = $event
    }

    onClickExcel() {
        this.isExcelModeEnabled = true;
        this.isEditModeEnabled = false;
    }

    onClickExitExcel() {
        this.isExcelModeEnabled = false;
    }

    onClickDownload() {
        Utils.httpDownloadMarketQuery(
            this.http,
            this.baseUrl,
            `SegmentSheet`,
            'SEGMENT');
    }

    onClickUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadSegmentSheet()]);
    }

    private loadInformation() {
        this.tableResponseState = APICallStatus.Waiting4Response;
        this.showReleaseLabel = false;
        this.title = `Segment Sheet (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear})`;
        this.subscriptions.forEach(x => x.unsubscribe())
        this.isAllLoaded = false;
        Utils.httpGetMarketQueryInformation(
            this.http
            , this.baseUrl
            , 'SEGMENT'
            , this
            , function (tthis: SegmentSheetComponent, result: KpiManagement[]) {
                if (result) {
                    setTimeout(() => {
                        result = result.filter(x => x.KpiDetail.length > 0);
                        result.forEach(x => {
                            if (!x.subtable) {
                                const row = x.KpiDetail[0]
                                let maxBreakdown = ''
                                let columns = Object.assign([], tthis.columnsToDisplay2);

                                if (row.COL4 != '') {
                                    maxBreakdown = 'COL4'
                                }
                                if (row.COL4 === '') {
                                    columns.splice(columns.indexOf('COL4'), 1)
                                    maxBreakdown = 'COL3'
                                }
                                if (row.COL3 === '') {
                                    columns.splice(columns.indexOf('COL3'), 1)
                                    maxBreakdown = 'COL2'
                                }
                                if (row.COL2 === '') {
                                    columns.splice(columns.indexOf('COL2'), 1)
                                    maxBreakdown = 'COL1'
                                }
                                if (row.COL1 === '') {
                                    columns.splice(columns.indexOf('COL1'), 1)
                                    maxBreakdown = 'COL0'
                                }

                                x.columns = columns
                                x.maxBreakdown = maxBreakdown
                                tthis.showReleaseLabel = tthis.showReleaseLabel || x.ApprovalStatus === 'RELEASED'
                            }
                        })
                        tthis.list = result
                        tthis.isAllLoaded = true;
                        tthis.tableResponseState = APICallStatus.ResponseDataOk;
                        tthis.updatefilter();
                    }, 50)
                }
                else {
                    tthis.dialog.showErrorDialog({ 'error': { 'message': 'Please configure the user to have permission to query market' } });
                    tthis.list = [];
                    tthis.tableResponseState = APICallStatus.ResponseEmptyOk;
                }
            }
            , function (tthis: SegmentSheetComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
                tthis.tableResponseState = APICallStatus.ResponseDataOk;
                tthis.list = [];
            }
        );
    }

    ////////////////
    //Filter section
    auxFilterPredicate(filters: string, data: any, parentLevel: boolean, showAllEnabled: boolean) {
        const matchFilter: any[] = [];
        const filterArray = filters.split('+').filter(x => x)
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items

        if (filterArray.length > 0) {
            filterArray.forEach((filter) => {
                let result = false;
                if (this.selectorKpiUnitFilter == '0')
                    result = data.KpiUnit.toLowerCase() === 'values' || data.KpiUnit.toLowerCase() === 'values lc'

                else
                    result = data.KpiUnit.toLowerCase() === 'units'

                if (parentLevel === true) {
                    const kpiname = data.KpiName ? data.KpiName : ''
                    const id = data.AccountID ? data.AccountID : ''
                    result = result && (kpiname.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        id.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
                }
                else {
                    if (this.selectorFilter == '1') {
                        const granularity = data.Granularity ? data.Granularity : ''
                        result = result && granularity.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    }
                    if (!showAllEnabled) {
                        result = result &&
                            (Utils.IsValidNumber(data.PYBUD) ||
                                Utils.IsValidNumber(data.FY1BUD) ||
                                Utils.IsValidNumber(data.FY2BUD) ||
                                Utils.IsValidNumber(data.FY3BUD) ||
                                Utils.IsValidNumber(data.MarketPYBUD) ||
                                Utils.IsValidNumber(data.MarketFY1BUD) ||
                                Utils.IsValidNumber(data.MarketFY2BUD) ||
                                Utils.IsValidNumber(data.MarketFY3BUD))
                    }
                }
                matchFilter.push(result);
            });
        }
        else {
            let result = true

            if (parentLevel === true) {
                result = true
            }
            else {
                if (!showAllEnabled) {
                    result = result &&
                        (Utils.IsValidNumber(data.PYBUD) ||
                            Utils.IsValidNumber(data.FY1BUD) ||
                            Utils.IsValidNumber(data.FY2BUD) ||
                            Utils.IsValidNumber(data.FY3BUD) ||
                            Utils.IsValidNumber(data.MarketPYBUD) ||
                            Utils.IsValidNumber(data.MarketFY1BUD) ||
                            Utils.IsValidNumber(data.MarketFY2BUD) ||
                            Utils.IsValidNumber(data.MarketFY3BUD))
                }
            }

            if (this.selectorKpiUnitFilter == '0')
                result = result && (data.KpiUnit.toLowerCase() === 'values' || data.KpiUnit.toLowerCase() === 'values lc')
            else if (this.selectorKpiUnitFilter == '1')
                result = result && data.KpiUnit.toLowerCase() === 'units'
            matchFilter.push(result);
        }
        if (!this.inclusiveMode)
            return matchFilter.some(Boolean);
        else
            return matchFilter.every(Boolean);

    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.filter = ''
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
                .concat(`+${(this.selectorKpiUnitFilter === '1') ? 'FilterUnits' : 'FilterValues'}`)
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
                .concat(`+${(this.selectorKpiUnitFilter === '1') ? 'FilterUnits' : 'FilterValues'}`)
            this.filter = filterString
        }
    }

    isBranchReporting() {
        const management = Utils.GetManagementChildrenByParent(this.defaults.managementids[0], this.defaults.managementid)
        return (this.router.url.toLowerCase() === '/actualbranchreporting' && (management?.BusinessLevel === 'MANAGEMENT' && management?.Groups?.length >= 1) || management?.BusinessLevel === 'FIELDBRANCH') as boolean
    }

    onBack() { }

    updateSelectorKpiUnit(value) {
        this.selectorKpiUnitFilter = value
        this.updatefilter()
    }

    get isLoading(): boolean {
        return this.tableResponseState === APICallStatus.Waiting4Response;
    }

    /////////
    // UI Logic
    public toggleRelease() {
        this.isReleaseActive = !this.isReleaseActive;
        this.releaseButtonText = this.isReleaseActive ? 'Abort Release' : 'Initiate Release', this.listComponent.clearSelectionList()
    }

    public toggleRevoke() {
        this.isRevokeActive = !this.isRevokeActive;
        this.revokeButtonText = this.isRevokeActive ? 'Abort Revoke' : 'Initiate Revoke', this.listComponent.clearSelectionList()
    }

    canUserRevoke() {
        return this.defaults.isMersyRevokerMarketQueryUser
            || this.defaults.isMersyOwnerMarketQueryUser
            || this.defaults.isMersyAdminMarketQueryUser
    }

    canUserRelease() {
        return this.canUserRevoke()
            || this.defaults.isMersyReporterMarketQueryUser
    }

    canUserUpdate() {
        return this.canUserRelease()
            || this.defaults.isMersyDelegateMarketQueryUser
    }
}
