import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, filter } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'fiscalyear',
    template: `Fiscal Year:<br />
                <ng-select (change)="onSelect($event)" style="width:120px; border: 1px white solid" class="top-main-select"
                    [items]="list"
                   bindLabel="label"
                   bindValue="value"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="defaults.fiscalYear">
                </ng-select>`
})

export class FiscalYearComponent implements OnInit {
    list: any[] = [];
    value: number = 0;

    oplLinks = ["/oplkpibranchreporting", "/oplkpimanagement"]
    previousOplRel: boolean | null = null;
    previousMQspec: boolean | null = null;
    protected _onDestroy = new Subject<void>();

    private now: Date;
    private year: number;
    private month: number;
    //////////////////////////////////////
    //// Remove this block after september 2025 and every where it is used in the code
    isMarketQuerySpec = false;
    MarketSections = ["/segment-sheet", "/market-sheet"]
    //////////////////////////////////
    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
        , private router: Router
        , private route: ActivatedRoute,


    ) {
        this.now = new Date();
        this.year = this.now.getFullYear();
        this.month = this.now.getMonth();
    }

    async ngOnInit() {
        //////////////////////////////////////
        //// Remove this block after september 2025
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd))
            .subscribe(async (event: any) => {
                await this.onInitAbstr(event.url);
            });

        ///////////////////////////////////////////
        //uncomment this section after september 2025
        // if (this.month === 9) {
        //    this.router.events.pipe(
        //        filter(event => event instanceof NavigationEnd))
        //        .subscribe(async (event: any) => {
        //        await this.onInitAbstr(event.url);
        //    });
        //}
        //else
        //    await this.onInitAbstr();
    }

    async onInitAbstr(url = "") {
        var isOplRel = this.oplLinks.includes(url);
        var marketQueryFrame = this.year === 2024 || (this.month < 9 && this.year === 2025);
        this.isMarketQuerySpec = marketQueryFrame && this.MarketSections.includes(url);

        var changeYearsTrigger = (isOplRel != this.previousOplRel && this.month === 9) || (this.isMarketQuerySpec != this.previousMQspec);
        if (changeYearsTrigger) {
            this.dialog.tthis = this;


            while (!this.defaults.loadInitialData) {
                await new Promise(r => setTimeout(r, 1000));
            }

            this.list = [];
            const localArrayList: any[] = []
            if (this.defaults.isMersyAdminUser === true ||
                this.defaults.isMersyOwnerUser === true) {
                for (let i = this.year + 3; i > this.year - 6; i--) {
                    localArrayList.push({ 'label': `${i - 1}/${i}`, 'value': i })
                }
            }
            else {
                if (isOplRel === false && this.month === 9
                    || (isOplRel === true && this.month === 9 && this.year === 2024)   /// PBI111835
                        || (this.isMarketQuerySpec)) {
                    var years = marketQueryFrame ? 2024 : this.year;
                    localArrayList.push({ 'label': `${years}/${years + 1}`, 'value': years + 1 })
                    localArrayList.push({ 'label': `${years - 1}/${years}`, 'value': years })
                }
                else if (this.month < 9)
                    localArrayList.push({ 'label': `${this.year - 1}/${this.year}`, 'value': this.year })
                else {
                    localArrayList.unshift({ 'label': `${this.year}/${this.year + 1}`, 'value': this.year + 1 })
                }
            }
            if (this.isMarketQuerySpec) {
                this.value = marketQueryFrame ? 2024 : this.year;
                this.changeFiscalYear()

            }
            else if (this.month === 9) {
                this.value = isOplRel === false ? this.year : this.year + 1;
                this.changeFiscalYear()
            }
            else if (marketQueryFrame) {
                this.value = this.month < 9 ? this.year : this.year + 1;
                this.changeFiscalYear()
            }
            //this.value = isOplRel === false ? this.year: this.year + 1;

            this.previousOplRel = isOplRel;
            this.previousMQspec = this.isMarketQuerySpec;

            this.list = localArrayList;
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    changeFiscalYear() {
        this.defaults.fiscalYear = this.value
        this.sendPost();
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.changeFiscalYear();
    }

    onSelect(l: any) {
        if (!l.value)
            return;
        this.value = +l.value;
        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();

    }

    onYes() {
        this.saveService.fireSave();
        this.changeFiscalYear();
    }

    sendPost() {
        var tthis = this;
        this.defaults.fiscalYear = this.value;
        this.http
            .post<string>(this.baseUrl + Utils.getFiscalYearAPI(), this.defaults.fiscalYear)
            .subscribe(() => {
                this.defaults.notifyFiscalYearChange();
            }, error => Utils.writeLog(tthis.http, tthis.baseUrl, error)); //error => console.log(error));
    }
}